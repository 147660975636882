import { ValidationRules } from '../../../shared/molecules/Form'
import { customerDetailsValidatorsMap } from './CustomerDetailsStep.helpers'

export interface CustomerDetailsStepConfig {
  buttonGoNextTheme: string
  buttonIcon: string | null
  validators: ValidationRules
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_COMPONENT_KEY = 'CustomerDetailsStep'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_COMPONENT_KEY_CONFIG_MAP: CustomerDetailsStepConfig = {
  buttonGoNextTheme: 'secondary',
  buttonIcon: null,
  // todo: mena: {... postalCode: 'filled|string|min:5|max:7', }
  validators: customerDetailsValidatorsMap
}
